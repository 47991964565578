import { Link } from "gatsby"
import React from "react"

import imgContactHeader from "../images/gfx/contact-header-bg.png"
import imgContactRequestInfo from "../images/gfx/contact-request-info.png"
import imgContactPhone from "../images/gfx/contact-questions.png"
import imgContactBookDemo from "../images/gfx/contact-demo.png"
import imgContactFooter from "../images/gfx/contact-footer-bg.png"

import "./contact.css";

const Contact = () => (
  <div className="contact" style={{ background: `url(${imgContactHeader}), url(${imgContactFooter}), #084e96`, backgroundSize: '100% auto, 100% auto, 100%', backgroundRepeat: 'no-repeat, no-repeat, repeat', backgroundPosition: 'center top, center bottom, left' }}>

    <h3 id='contact'>Contact us to find out how we can help</h3>

    <div className='contact__cta-parent' style={{ maxWidth: '1200px', marginLeft: 'auto', marginRight: 'auto'}}>
      <div className='contact__cta'>
        <div><img src={imgContactRequestInfo} alt="" /></div>
        <h4>Request more info</h4>
        <p className="contact__cta__text">Want to learn more?<br />Request an information pack</p>
        <Link to="/request-info/" className="btn">Request info</Link>
      </div>
      <div className='contact__cta'>
        <div><img src={imgContactPhone} alt="" /></div>
        <h4>Questions?</h4>
        <p className="contact__cta__text">Call us on <a className="contact-tel" href="tel:+443333449868">0333 344 9868</a> and speak with a specialist to answer all your questions</p>
        <a href="tel:+443333449868" className="btn">Get in touch</a>
      </div>
      <div className='contact__cta'>
        <div><img src={imgContactBookDemo} alt="" /></div>
        <h4>Book a demo</h4>
        <p className="contact__cta__text">Schedule a demo and discuss your specific needs</p>
        <Link to="/book-demo/" className="btn">Book a demo</Link>
      </div>

    </div>

    <div id="mc_embed_signup" className='contact__newsletter' style={{ maxWidth: '1200px', marginLeft: 'auto', marginRight: 'auto'}}>
      <h4>Signup for our latest news</h4>
      <p>Receive information on latest developments and great offers via our newsletter</p>
      <form action="https://shuttleid.us5.list-manage.com/subscribe/post?u=237986bc41652c1923086f2bf&amp;id=e3c01c6f64" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
        <div id="mc_embed_signup_scroll">
          <input type="email" defaultValue="" name="EMAIL" className="email" id="mce-EMAIL" placeholder="Email address" required />
          {/* <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups--> */}
          <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
            <input type="text" name="b_237986bc41652c1923086f2bf_e3c01c6f64" tabIndex="-1" value="" />
          </div>
          <div className="clear foot">
            <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="btn button" />
          </div>
        </div>
      </form>
    </div>
  </div>
);

export default Contact
